<template>
  <div id="lockedview">
    <div class="textContent">
      <div class="danger">
        <svg-icon name="danger" size="80" color="#FFBE17"></svg-icon>
      </div>
      <div class="text">
        <h1>對不起，您的帳號已凍結</h1>
        <h1>凍結理由:我們懷疑您的身份造假</h1>
        <h4>帳號被凍結有可能是您違反了Finger的安全守則，或者由於您的帳號產生了安全風險而造成，您可以:</h4>
      </div>
    </div>
    <div class="content">
      <div class="card">
        <div class="left">
          <svg-icon name="form" size="60" color="#000"></svg-icon>
        </div>
        <div class="right">
          <h1>查看相關規定</h1>
          <p>您可以通過查看相關規則來了解問題。</p>
        </div>
      </div>
      <div class="card">
        <div class="left">
          <svg-icon name="note" size="60" color="#000"></svg-icon>
        </div>
        <div class="right">
          <h1>我要申訴</h1>
          <p>您可以通過查看相關規則來了解問題。</p>
        </div>
      </div>
      <div class="card">
        <div class="left">
          <svg-icon name="call-center" size="60" color="#000"></svg-icon>
        </div>
        <div class="right">
          <h1>聯繫客服</h1>
          <p>您可以通過查看相關規則來了解問題。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
export default {
  name:'LockedView',
  components: {
    SvgIcon
  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#lockedview{
  width: 100%;
  height: 100%;
  background: #212429;
  padding: 10%;
  box-sizing: border-box;
  padding-top: 100px;
}
.text{
  color: aliceblue;
}
.content{
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  width: 1400px;
}
.textContent{
  display: flex;
  width: 50%;
}
.card{
  min-width: 400px;
  height: 168px;
  border-radius: 20px;
  background: #FBFBFB;
  display: flex;
  transition: .4s;
}
h4{
  margin-top: 60px;
}
.danger{
  margin-right: 60px;
}
.right{
  width: 70%;
  padding-top: 20px;
}
.left{
  width: 30%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.card:hover{
  cursor: pointer;
  transform: translateY(-10px);
  background: #bdbebd;
}

</style>
